<template>
  <v-container>
    <v-row>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
      <v-col class="col-12 col-sm-8 pa-5">
        <h1>Honor Tag Privacy Policy</h1>
        REV 10/10/2019<br>
        <br>
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.honortag.com (the “Site”).<br>
        <br>
        PERSONAL INFORMATION WE COLLECT<br>
        <br>
        When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”<br>
        <br>
        We collect Device Information using the following technologies:<br>
        <br>
        - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org.</a><br>
        <br>
        - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.<br>
        <br>
        - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.<br>
        <br>
        - “Location services” track your location via GPS on your mobile device.<br>
        <br>
        Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”<br>
        <br>
        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.<br>
        <br>
        In addition, when you create an account with Honor Tag, we collect your first and last name, an email address and password you provide when you create your account.<br>
        <br>
        <br>
        INFORMATION WE COLLECT WHEN YOU CREATE A STORY<br>
        <br>
        We collect the information that you provide when you voluntarily create a User Story. This information may be seen by other Users. Additional personal details that you provide in your User Story (for example, a profile image, your name, age, location, service history, etc.), will be visible to other Users.<br>
        <br>
        USER PROVIDED CONTENT<br>
        <br>
        Information you provide about yourself or other living individuals when you voluntarily contribute to User Stories you create or add to User Stories created by others or when you participate in community discussions.<br>
        <br>
        For example, you might contribute details (stories, images) about you or other users’ military records.<br>
        <br>
        Information about deceased persons is not Personal Information under this Privacy Statement.<br>
        <br>
        SOCIAL MEDIA INFORMATION<br>
        <br>
        If you use social media, such as Facebook or Google+ to log into Honor Tag, we collect information from your social media profile that you choose to provide to us.<br>
        <br>
        If you interact with social media through the Services, for example “Like,” “Tweet,” “Pin,” or “Follow Us” links to sites such as Facebook, Twitter, Pinterest, Instagram, and YouTube, Honor Tag will collect these interactions and whatever account information these services make available to us.<br>
        <br>
        Your interactions with these features are governed by the privacy statement of the applicable third-party company.<br>
        <br>
        INFORMATION COLLECTED FROM OTHER SOURCES<br>
        <br>
        Honor Tag collects records from various sources, usually from official record sources, including newspapers, as well as birth, death, military and marriage records, which may contain Personal Information relating to you. <br>
        <br>
        HEALTH RELATED INFORMATION<br>
        <br>
        Honor Tag is not a covered entity under the Health Insurance Portability and Accountability Act (“HIPAA”), and as a result no User Information provided is subject to or protected by HIPAA.<br>
        <br>
        HOW DO WE USE YOUR PERSONAL INFORMATION?<br>
        <br>
        We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).<br>
        Additionally, we use this Order Information to:<br>
        <br>
        Communicate with you;<br>
        <br>
        Screen our orders for potential risk or fraud; and<br>
        <br>
        When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.<br>
        <br>
        We use your Personal Information to provide, personalize, improve, update and expand our Services, including helping you create, and providing insights about, your User Stories, issuing surveys and questionnaires to collect additional user information for use in the Services, as well as facilitating product development and research initiatives.<br>
        <br>
        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).<br>
        <br>
        SHARING YOUR PERSONAL INFORMATION<br>
        <br>
        We share your Personal Information with third parties to help us use your Personal Information, as described above. We use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information <a href="https://www.google.com/intl/en/policies/privacy/">here</a>. You can also opt-out of Google Analytics <a href="https://tools.google.com/dlpage/gaoptout">here</a>.<br>
        <br>
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.<br>
        <br>
        BEHAVIOURAL ADVERTISING<br>
        <br>
        As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative's ("NAI") educational <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">page</a>.
        <br>
        You can opt out of targeted advertising by:<br>
            <a href="https://www.facebook.com/settings/?tab=ads">Facebook</a><br>
            <a href="https://www.google.com/settings/ads/anonymous">Google</a><br>
        <br>
        Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out <a href="http://optout.aboutads.info/">portal</a>.<br>
        <br>
        DO NOT TRACK<br>
        Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.<br>
        <br>
        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.<br>
        <br>
        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.<br>
        <br>
        DATA RETENTION<br>
        When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.<br>
        <br>
        MINORS<br>
        The Site is not intended for individuals under the age of 13.<br>
        <br>
        CHANGES<br>
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.<br>
        <br>
        CONTACT US<br>
        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@honor-tag.com or by mail using the details provided below:<br>
        <br>
        146 Ingle Place, Alexandria, VA, 22304, United States<br>
      </v-col>
      <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Privacy'
  }
</script>

<style scoped>

</style>